import {Button, Checkbox, FormField, Header, Icon, Label, Modal, Table} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Role} from "../../../base/enums/Role";
import {Month} from "../../../base/enums/Month";
import {FilterUtils} from "../../../base/util/FilterUtils";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import Translate from "../../../components/translation/Translate";
import toast from "react-hot-toast";
import {VacationReportDto, VacationReportItemDto} from '../dto/VacationReportDto'
import {
  EVacationType,
  getVacationsByYearForCurrentUser,
  getVacationsForUser, synchronizeVacations,
  VacationDto
} from '../../../api/generated-sources'
import {LocalDate} from '../../../build/core/dto/LocalDate'
import moment from "moment";

export function VacationTable() {

  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
  });
  const [convertToDays, setConvertToDays] = useState<boolean>(false);
  const [vacations, setVacations] = useState<VacationDto[]>();
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [report, setReport] = useState<VacationReportDto>();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const { id } = useParams<{ id: string }>();
  const auth = useContext<Auth>(AuthContext);
  const canSync = auth.hasRole(Role.HR_MANAGER) || auth.isAdmin();

  const loadTableForYear = (year : number) => {
    if (id) {
      getVacationsForUser(+id,{
        year: year,
      })
        .then(data => {
          parseReport(data.vacation);
          setVacations(data.vacation);
          setSyncState({inProgress: false});
        })
        .catch(console.error);

    } else {
      getVacationsByYearForCurrentUser({
        year: year,
      })
        .then(data => {
          parseReport(data.vacation);
          setVacations(data.vacation);
          setSyncState({inProgress: false});
        })
        .catch(console.error);
    }
  }

  useEffect(() => {
    loadTableForYear(year);
  }, [id, year]);

  const parseReport = (vacations : VacationDto[]) => {
    let paidLeaves : {[key: number]: VacationReportItemDto} = {};
    Month.ALL.forEach((month) => {
      paidLeaves[month.id] = {
        month: month.id,
        year: year,
        hours: 0,
        entries: []
      }
    });
    let unpaidLeaves : {[key: number]: VacationReportItemDto} = {};
    Month.ALL.forEach((month) => {
      unpaidLeaves[month.id] = {
        month: month.id,
        year: year,
        hours: 0,
        entries: []
      }
    });
    let illness : {[key: number]: VacationReportItemDto} = {};
    Month.ALL.forEach((month) => {
      illness[month.id] = {
        month: month.id,
        year: year,
        hours: 0,
        entries: []
      }
    });

    let paidLeaveTotalHours = 0;
    let unpaidLeaveTotalHours = 0;
    let illnessTotalHours = 0;
    vacations.forEach(entry => {
      let date = new LocalDate(entry.date);
      if (entry.type === EVacationType.NONPAID_LEAVE) {
        unpaidLeaves[date.getMonth() + 1].hours += entry.hours;
        unpaidLeaves[date.getMonth() + 1].entries.push(entry);
        unpaidLeaveTotalHours += entry.hours;
      } else if (entry.type === EVacationType.PAID_LEAVE) {
        paidLeaves[date.getMonth() + 1].hours += entry.hours;
        paidLeaves[date.getMonth() + 1].entries.push(entry);
        paidLeaveTotalHours += entry.hours;
      } else {
        illness[date.getMonth() + 1].hours += entry.hours;
        illness[date.getMonth() + 1].entries.push(entry);
        illnessTotalHours += entry.hours;
      }
    });

    setReport({paidLeave: paidLeaves, paidLeaveTotalHours: paidLeaveTotalHours, unpaidLeave: unpaidLeaves, unpaidLeaveTotalHours: unpaidLeaveTotalHours, illness: illness, illnessTotalHours: illnessTotalHours});
  }

  const synchronizeReport = () => {
    if (syncState.inProgress) {
      return;
    }
    setSyncState({
      inProgress: true,
    });

    const promise = synchronizeVacations({
      year: year,
      userId: id ? +id : null,
    });
    toast.promise(promise,
      {
        loading: `Synchronizuji dovolené za rok ${year}...`,
        success: "Hotovo!",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(data => {
        parseReport(data);
        setVacations(data);
        setSyncState({inProgress: false});
      })
      .catch(reason => {
        console.error(reason.message);
        setSyncState({...syncState, error: true});
      })
  };

  return (
    <>
      {!report || !vacations ? <SimpleLoader text={"Načítám data"} />
      : <>
          <Header as="h3" textAlign="center">
            DOVOLENÉ
          </Header>


            <Modal
              className={"vacations-detail"}
              onClose={() => setIsDetailOpen(false)}
              open={isDetailOpen}
              size={"small"}
              closeIcon
            >
              <Modal.Header>
                <Icon name={'sun'} /> Detail dovolených za rok {year}
              </Modal.Header>
              <Modal.Content>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Datum</Table.HeaderCell>
                      <Table.HeaderCell>Typ</Table.HeaderCell>
                      <Table.HeaderCell>Délka</Table.HeaderCell>
                      <Table.HeaderCell>Poznámka</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {
                        vacations.length === 0 && <Table.Row>
                          <Table.Cell textAlign={'center'} colSpan={4}>
                            Tento rok není evidovaná žádná dovolená
                          </Table.Cell>
                        </Table.Row>
                      }
                      {vacations.map(vacation => {
                        return <Table.Row key={vacation.id}>
                          <Table.Cell>{moment(vacation.date).format("D. M. YYYY")}</Table.Cell>
                          <Table.Cell>
                            {(vacation.type === EVacationType.ILLNESS || vacation.type === EVacationType.MEDICAL) && <Label icon={'hotel'} color={'yellow'} content={'Doktor / Nemoc'}/>}
                            {vacation.type === EVacationType.PAID_LEAVE && <Label icon={'sun'} color={'teal'} content={'Dovolená'}/>}
                            {vacation.type === EVacationType.NONPAID_LEAVE && <Label icon={'sun'} color={'olive'} content={'Neplacená dovolená'}/>}
                          </Table.Cell>
                          <Table.Cell>{vacation.hours === 8 ? 'Celý den' : (vacation.hours + ' (h)')}</Table.Cell>
                          <Table.Cell>{vacation.note}</Table.Cell>
                        </Table.Row>
                      })}
                  </Table.Body>
                </Table>
              </Modal.Content>
              <Modal.Actions>
              </Modal.Actions>
            </Modal>


            <Table basic striped selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} key="0">
                    <Label color='red' ribbon>{year}</Label>
                  </Table.HeaderCell>
                  {Month.ALL.map((month) => {
                    return (
                        <Table.HeaderCell
                            textAlign={"center"}
                            width={1}
                            key={month.id}
                        >
                          <strong><Translate label={month.translation} /></strong>
                        </Table.HeaderCell>
                    );
                  })}
                  <Table.HeaderCell textAlign={"center"}>
                    Celkem {convertToDays ? ' dnů' : ' hodin'}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row onClick={() => setIsDetailOpen(true)}>
                  <>
                    <Table.Cell singleLine><strong>Placená</strong></Table.Cell>
                    {
                      Object.entries(report.paidLeave).map(([key, value]) => {
                        return <Table.Cell textAlign={'center'} key={'paidleave-' + key} singleLine>{convertToDays ? (value.hours / 8) : value.hours}</Table.Cell>
                      })
                    }
                    <Table.Cell singleLine textAlign={'center'}><strong>{convertToDays ? (report.paidLeaveTotalHours / 8) : report.paidLeaveTotalHours}</strong></Table.Cell>
                  </>
                </Table.Row>
                <Table.Row onClick={() => setIsDetailOpen(true)}>
                  <>
                    <Table.Cell singleLine><strong>Doktor / Nemoc</strong></Table.Cell>
                    {
                      Object.entries(report.illness).map(([key, value]) => {
                        return <Table.Cell textAlign={'center'} key={'illness-' + key} singleLine>{convertToDays ? (value.hours / 8) : value.hours}</Table.Cell>
                      })
                    }
                    <Table.Cell singleLine textAlign={'center'}><strong>{convertToDays ? (report.illnessTotalHours / 8) : report.illnessTotalHours}</strong></Table.Cell>
                  </>
                </Table.Row>
                <Table.Row onClick={() => setIsDetailOpen(true)}>
                  <>
                    <Table.Cell singleLine><strong>Neplacená</strong></Table.Cell>
                    {
                      Object.entries(report.unpaidLeave).map(([key, value]) => {
                        return <Table.Cell textAlign={'center'} key={'unpaidleave-' + key} singleLine>{convertToDays ? (value.hours / 8) : value.hours}</Table.Cell>
                      })
                    }
                    <Table.Cell singleLine textAlign={'center'}><strong>{convertToDays ? (report.unpaidLeaveTotalHours / 8) : report.unpaidLeaveTotalHours}</strong></Table.Cell>
                  </>
                </Table.Row>
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={'14'}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      {
                        canSync &&
                        <Icon
                          className={"clickable"}
                          disabled={syncState.inProgress}
                          name={
                            syncState.error
                              ? "exclamation triangle"
                              : "sync alternate"
                          }
                          loading={syncState.inProgress}
                          color={syncState.error ? "red" : "grey"}
                          onClick={() => synchronizeReport()}
                        />
                      }

                      <span>
                        <Button
                          icon
                          labelPosition="left"
                          size="small"
                          positive
                          disabled={syncState.inProgress || year <= FilterUtils.MIN_YEAR}
                          onClick={() => {
                            setYear(year - 1);
                          }}
                        >
                          <Icon name="chevron left" /> {year - 1}
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          icon
                          labelPosition="right"
                          size="small"
                          positive
                          disabled={syncState.inProgress || year >= new Date().getFullYear()}
                          onClick={() => {
                            setYear(year + 1);
                          }}
                        >
                          {year + 1} <Icon name="chevron right" />
                        </Button>
                      </span>

                      <FormField>
                        <Checkbox toggle label={'Přepočítat na dny'} onChange={() => setConvertToDays(!convertToDays)}></Checkbox>
                      </FormField>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>


        </>
      }
    </>
  );
}
